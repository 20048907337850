div[aria-label='Remove English'] {
  visibility: hidden;
}

.surveys-manage-page {
  .ri-close-line {
    right: 45px !important;

    &:hover {
      cursor: pointer;
    }
  }
}

.answer-error {
  .invalid-feedback {
    display: block;
  }
}

.survey-container {
  .drag-icon {
    z-index: 1;
    top: 17px;
  }
}

.surveys-list {
  .nav-customs-bordered.nav {
    max-width: 500px;
  }

  .table-card {
    .card-body {
      overflow-x: auto;
    }
  }
}

.preview-survey-modal {
  .modal-body {
    max-height: 550px;
    overflow-y: auto;

    .nav-link {
      background: none;
    }
  }
}

.overview-answers-card {
  padding: 0;
}

.question-header-overflow {
  max-width: 110px;
  min-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.user-answers-table {
  max-width: 1000px;
  overflow-x: auto;
}

.user-details-overflow {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.survey-responses {
  .gridjs-sort {
    min-width: 10px;
  }
}

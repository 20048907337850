//
// Dropzone
//

.dropzone {
  min-height: 110px;
  max-height: 110px;

  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}card-bg-custom);
  border-radius: 6px;

  .dz-message {
    font-size: 18px;
    width: 100%;
  }
}

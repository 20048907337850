.image-container {
  background: $purple-100;

  i {
    font-size: 20px;
    color: $purple-200;
  }
}

.media-library-modal {
  table {
    tr {
      &:hover {
        cursor: pointer;
        background: $gray-200;
      }
    }
  }

  .selected-row {
    background: $gray-200;
  }

  .modal-body {
    max-height: 75vh;
    overflow-y: auto;
  }
}

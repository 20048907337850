//
// _merge-user.scss
//

.user-option.selected {
  background-color: $light-blue;
}

.user-option {
  &:hover {
    background-color: $black-87;
    cursor: pointer;
  }
}

//
// _detail-info-modal.scss
//

.info-body--tooltip {
  .tooltip {
    z-index: 1;
  }
  .tooltip-inner {
    background-color: #020626;
  }
}
.active-i {
  color: #0039c7;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type='time']::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.text-green-500 {
  color: $green-500;
}

.bg-green-100 {
  background: $green-100;
}

.border-green-500 {
  border-color: $green-500;
}

.bg-dark-gray {
  background: $dark-gray;
}

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--vz-input-bg);
    border-color: var(--vz-input-border);

    &:hover {
      border-color: var(--vz-input-border);
    }
  }

  .css-t3ipsp-control {
    background-color: var(--vz-input-bg);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;

    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }

  .css-1nmdiq5-menu {
    background-color: var(--vz-input-bg);

    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--vz-input-bg);

      &:active {
        background-color: var(--vz-input-bg);
      }
    }

    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }

  .css-12jo7m5 {
    color: $heading-color;
  }

  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.text-highlight {
  color: var(--system-blue-400, #73dce9);
}

.two-line-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-highlight-primary {
  color: var(--accent-purple-500, #0039c7);
}

.text-disabled {
  color: $black-83;
}

.flash-light-off-success path {
  fill: $green-500;
}

.flash-light-off-danger path {
  fill: $red-500;
}

.checkin-in-disabled path {
  fill: $text-muted;
}

.navigation-container {
  background: $black-87;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.course-navigation {
  background: $black-87;
  padding-bottom: 15px;
  padding-top: 15px;
}

.settings-navigation-container {
  background: $white;
  position: sticky;
  bottom: 0;
  width: calc(100% + 25px);
  margin: 0 -1.5rem;
  z-index: 2;
  padding-bottom: 15px;
  padding-top: 15px;
}

.sticky-row {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.facilities-dropdown-container {
  .form-select__single-value {
    color: $white;
  }

  .form-select__control {
    background: #223558;
    color: #7f85a1;
    border: none;
  }

  .form-select__input-container {
    color: #7f85a1;
  }

  .form-select__dropdown-indicator {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .form-select__indicator-separator {
    display: none;
  }

  .form-select__option--is-selected {
    background: transparent !important;
    color: #1ac1bd;
  }

  .form-select__label,
  .form-select__item,
  .form-select__item-container {
    display: inline-block;
  }
}

.form-select__value-container--has-value {
  flex-wrap: nowrap !important;
}

.group-multi-select {
  .form-select__option--is-selected {
    span {
      &:hover {
        cursor: pointer;
      }
    }
    background: transparent !important;
    color: #1ac1bd;
  }
}

.company-status {
  width: 100%;

  .nav-item {
    width: 50%;
  }
  .nav-link {
    width: 100%;
  }
}

.company-switch-warning-modal {
  .hstack {
    align-items: stretch;
  }
  button {
    height: auto;
  }
}

.w-fit-content {
  width: fit-content;
}

.company-logo {
  background: #223558;
}

.users-search {
  background: $black-87;
  input {
    background: $white !important;
    border: 1px solid #caccd8 !important;
  }
}

.initial-user-search {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-results-user-search {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-user-details {
  .is-invalid {
    .form-select__control {
      border-color: $red;
    }
  }

  .form-select__indicator-separator {
    display: none;
  }
}

.info-body--tooltip {
  .tooltip {
    z-index: 2 !important;
  }
  .tooltip-inner {
    max-width: 280px;
  }
}

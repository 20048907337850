.push-notifications-table,
.surveys-table {
  .message {
    width: 150px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ri-external-link-line:not(.text-disabled) {
    &:hover {
      color: $purple;
    }
  }
}

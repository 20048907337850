//
// emoji-picker.scss
//

.fg-emoji-picker {
  width: 250px !important;
  box-shadow: $box-shadow !important;
  top: auto !important;
  bottom: 130px;

  * {
    font-family: $font-family-base !important;
    color: var(--#{$prefix}body-color) !important;
  }
  @media (max-width: 991.98px) {
    left: 14px !important;
    top: auto !important;
    bottom: 118px;
  }

  .fg-emoji-picker-container-title {
    color: $dark !important;
  }

  .fg-emoji-picker-search {
    height: 40px !important;
    input {
      background-color: $input-bg !important;
      color: $input-color !important;
      padding: $input-btn-padding-y $input-btn-padding-x !important;
      font-size: $font-size-base !important;
      &::placeholder {
        color: $input-placeholder-color !important;
      }
    }

    svg {
      fill: var(--#{$prefix}body-color) !important;
      right: 11px;
      top: 12px;
    }
  }

  .fg-emoji-picker-categories {
    background-color: $light !important;
    li.active {
      background-color: rgba(var(--bs-primary-rgb), 0.2);
    }
    a {
      &:hover {
        background-color: rgba(var(--bs-primary-rgb), 0.2);
      }
    }
  }
}

.fg-emoji-picker-grid > li:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.2) !important;
}

a.fg-emoji-picker-close-button {
  background-color: darken($light, 4%) !important;
}
